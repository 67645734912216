export const documentsData = [
  {
    id: 1,
    uploadDate: "Mar 6, 2024 02:37:22 PM",
    documentName: "Business Plan.pdf",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar.png",
    shareWith: "Investors",
    action: "Mar 16, 2024 02:37:22 PM",
    title: "Business Plan"
  },
  {
    id: 2,
    uploadDate: "Mar 4, 2024 11:20:56 AM",
    documentName: "Pitch Deck - Q1 2024.pptx",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar.png",
    shareWith: "Investors",
    action: "",
    title: "Pitch Deck - Q1 2024"
  },
  {
    id: 3,
    uploadDate: "Mar 14, 2024 11:20:56 AM",
    documentName: "Investor Pitch Deck - Mar 2024.pdf",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar.png",
    shareWith: "Investors",
    action: "Mar 28, 2024 04:01:11 PM",
    title: "Investor Pitch Deck - Mar 2024"
  },
  {
    id: 4,
    uploadDate: "Mar 1, 2024 10:45:08 AM",
    documentName: "Quality Assurance (QA) Plan",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_4.png",
    shareWith: "Investors",
    action: "Mar 22, 2024 12:45:15 PM",
    title: "Quality Assurance (QA) Plan"
  },
  {
    id: 5,
    uploadDate: "Mar 10, 2024 11:20:56 AM",
    documentName: "Marketing Strategy.pdf",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_4.png",
    shareWith: "Marketing Team",
    action: "Mar 30, 2024 11:20:56 PM",
    title: "Marketing Strategy"
  },
  {
    id: 6,
    uploadDate: "Mar 22, 2024 12:45:15 PM",
    documentName: "Partnership Agreement - DM (2024).pdf",
    uploadBy: "Cameron Williamson",
    uploadByImage: "/images/img_avatar_7.png",
    shareWith: "Partners",
    action: "Mar 22, 2024 12:45:15 PM",
    title: "Partnership Agreement - DM (2024)"
  },
  {
    id: 7,
    uploadDate: "Jul 5, 2024 09:30:45 AM",
    documentName: "Financial Forecast.xlsx",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar.png",
    shareWith: "Investors",
    action: "Jul 15, 2024 09:30:45 AM",
    title: "Financial Forecast"
  },
  {
    id: 8,
    uploadDate: "Jul 10, 2024 03:45:12 PM",
    documentName: "Product Roadmap - Q3 2024.docx",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_4.png",
    shareWith: "Product Team",
    action: "Jul 20, 2024 03:45:12 PM",
    title: "Product Roadmap - Q3 2024"
  },
  {
    id: 9,
    uploadDate: "Jul 12, 2024 11:55:30 AM",
    documentName: "User Feedback Analysis.pdf",
    uploadBy: "Cameron Williamson",
    uploadByImage: "/images/img_avatar_7.png",
    shareWith: "Product Managers",
    action: "Jul 26, 2024 11:55:30 AM",
    title: "User Feedback Analysis"
  },
  {
    id: 10,
    uploadDate: "Jul 8, 2024 02:15:10 PM",
    documentName: "Sales Presentation - July 2024.pptx",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar.png",
    shareWith: "Sales Team",
    action: "Jul 18, 2024 02:15:10 PM",
    title: "Sales Presentation - July 2024"
  },
  {
    id: 11,
    uploadDate: "Jul 17, 2024 10:10:05 AM",
    documentName: "HR Policies and Procedures",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_4.png",
    shareWith: "HR Department",
    action: "Jul 27, 2024 10:10:05 AM",
    title: "HR Policies and Procedures"
  },
  {
    id: 12,
    uploadDate: "Jul 19, 2024 04:20:30 PM",
    documentName: "Customer Survey Results.xlsx",
    uploadBy: "Cameron Williamson",
    uploadByImage: "/images/img_avatar_7.png",
    shareWith: "Marketing Team",
    action: "Jul 29, 2024 04:20:30 PM",
    title: "Customer Survey Results"
  },
  {
    id: 13,
    uploadDate: "Jul 14, 2024 12:30:15 PM",
    documentName: "Legal Compliance Checklist",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar.png",
    shareWith: "Legal Team",
    action: "Jul 24, 2024 12:30:15 PM",
    title: "Legal Compliance Checklist"
  },
  {
    id: 14,
    uploadDate: "Jul 21, 2024 11:40:50 AM",
    documentName: "IT Infrastructure Plan",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_4.png",
    shareWith: "IT Department",
    action: "Jul 31, 2024 11:40:50 AM",
    title: "IT Infrastructure Plan"
  },
  {
    id: 15,
    uploadDate: "Jul 23, 2024 03:55:25 PM",
    documentName: "Employee Training Manual",
    uploadBy: "Cameron Williamson",
    uploadByImage: "/images/img_avatar_7.png",
    shareWith: "Training Team",
    action: "May 2, 2024 03:55:25 PM",
    title: "Employee Training Manual"
  },
  {
    id: 16,
    uploadDate: "Jul 25, 2024 09:15:40 AM",
    documentName: "Budget Proposal - Q4 2024.pdf",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar.png",
    shareWith: "Finance Team",
    action: "May 4, 2024 09:15:40 AM",
    title: "Budget Proposal - Q4 2024"
  },
  {
    id: 17,
    uploadDate: "Jul 27, 2024 02:35:10 PM",
    documentName: "Market Research Report",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_4.png",
    shareWith: "Strategy Team",
    action: "May 7, 2024 02:35:10 PM",
    title: "Market Research Report"
  },
  {
    id: 18,
    uploadDate: "Jul 29, 2024 10:50:55 AM",
    documentName: "Client Contracts - 2024",
    uploadBy: "Cameron Williamson",
    uploadByImage: "/images/img_avatar_7.png",
    shareWith: "Legal Team",
    action: "May 9, 2024 10:50:55 AM",
    title: "Client Contracts - 2024"
  },
  {
    id: 19,
    uploadDate: "Jul 31, 2024 04:05:20 PM",
    documentName: "Product Specifications - V2",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar_12.png",
    shareWith: "Product Managers",
    action: "May 11, 2024 04:05:20 PM",
    title: "Product Specifications - V2"
  },
  {
    id: 20,
    uploadDate: "May 2, 2024 11:15:35 AM",
    documentName: "Employee Satisfaction Survey",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_7.png",
    shareWith: "HR Department",
    action: "May 12, 2024 11:15:35 AM",
    title: "Employee Satisfaction Survey"
  },
  {
    id: 21,
    uploadDate: "May 4, 2024 03:25:10 PM",
    documentName: "Project Timeline - Q4 2024",
    uploadBy: "Cameron Williamson",
    uploadByImage: "/images/img_avatar_1.png",
    shareWith: "Project Managers",
    action: "May 14, 2024 03:25:10 PM",
    title: "Project Timeline - Q4 2024"
  },
  {
    id: 22,
    uploadDate: "May 6, 2024 09:40:45 AM",
    documentName: "Business Development Plan",
    uploadBy: "Annette Black",
    uploadByImage: "/images/img_avatar_2.png",
    shareWith: "Business Development Team",
    action: "May 16, 2024 09:40:45 AM",
    title: "Business Development Plan"
  },
  {
    id: 23,
    uploadDate: "May 8, 2024 02:50:30 PM",
    documentName: "Social Media Strategy - Q4 2024",
    uploadBy: "Darlene Robertson",
    uploadByImage: "/images/img_avatar_3.png",
    shareWith: "Marketing Team",
    action: "May 18, 2024 02:50:30 PM",
    title: "Social Media Strategy - Q4 2024"
  }  
];

export const projectsData = [
    {
        projectName: "Lorem Ipsum Project",
        target: "USD 5,000,000",
        stage: "Angel Round",
        milestone: "Development",
        status: "Active",
      },
      {
        projectName: "Angel Round Investment",
        target: "USD 1,000,000",
        stage: "Seed A",
        milestone: "Project Launch",
        status: "Active",
      },
      {
        projectName: "Taxi : Mobile app\n",
        target: "USD 2,500,000",
        stage: "Seed B",
        milestone: "Prototype Completion",
        status: "In Progress",
      },
      {
        projectName: "Angel Round Investment",
        target: "USD 4,000,000",
        stage: "Series A",
        milestone: "Product Launch",
        status: "Stand by",
      },
      {
        projectName: "E-commerce Platform - Series B",
        target: "USD 7,000,000",
        stage: "Series B",
        milestone: "Expansion",
        status: "Active",
      },
      {
        projectName: "Healthcare AI Solution",
        target: "USD 3,500,000",
        stage: "Seed A",
        milestone: "Clinical Trials",
        status: "Active",
      },
      {
        projectName: "Real Estate Tech - Seed C",
        target: "USD 2,200,000",
        stage: "Seed C",
        milestone: "Platform Development",
        status: "In Progress",
      },
      {
        projectName: "Fintech App - Series A",
        target: "USD 6,500,000",
        stage: "Series A",
        milestone: "Beta Testing",
        status: "Stand by",
      },
      {
        projectName: "SaaS for Education",
        target: "USD 1,800,000",
        stage: "Seed B",
        milestone: "User Acquisition",
        status: "Active",
      },
      {
        projectName: "Food Delivery Service",
        target: "USD 4,500,000",
        stage: "Angel Round",
        milestone: "Market Expansion",
        status: "Active",
      },
      {
        projectName: "AI-driven Marketing Platform",
        target: "USD 5,200,000",
        stage: "Series B",
        milestone: "Scaling",
        status: "In Progress",
      },
      {
        projectName: "Clean Energy Startup",
        target: "USD 3,000,000",
        stage: "Seed A",
        milestone: "Product Development",
        status: "Active",
      },
      {
        projectName: "Telehealth Platform",
        target: "USD 2,000,000",
        stage: "Seed C",
        milestone: "Regulatory Approval",
        status: "Stand by",
      },
      {
        projectName: "Mobile Gaming App - Series A",
        target: "USD 4,800,000",
        stage: "Series A",
        milestone: "Global Launch",
        status: "Active",
      },
      {
        projectName: "Blockchain Solutions",
        target: "USD 2,700,000",
        stage: "Seed B",
        milestone: "Platform Testing",
        status: "In Progress",
      },
      {
        projectName: "EduTech Startup",
        target: "USD 1,500,000",
        stage: "Angel Round",
        milestone: "Curriculum Development",
        status: "Active",
      },
      {
        projectName: "Fashion E-commerce",
        target: "USD 3,300,000",
        stage: "Seed A",
        milestone: "Brand Partnerships",
        status: "Active",
      },
      {
        projectName: "AgriTech Innovation",
        target: "USD 4,600,000",
        stage: "Seed B",
        milestone: "Field Trials",
        status: "In Progress",
      },
      {
        projectName: "Health & Wellness App",
        target: "USD 2,400,000",
        stage: "Series A",
        milestone: "App Launch",
        status: "Stand by",
      },
      {
        projectName: "Automotive Tech",
        target: "USD 5,700,000",
        stage: "Series B",
        milestone: "Product Enhancement",
        status: "Active",
      },
      {
        projectName: "Eco-friendly Products",
        target: "USD 2,100,000",
        stage: "Seed A",
        milestone: "Manufacturing",
        status: "In Progress",
      },
      {
        projectName: "Music Streaming Service",
        target: "USD 3,700,000",
        stage: "Seed B",
        milestone: "Platform Development",
        status: "Active",
      },
      {
        projectName: "Cybersecurity Startup",
        target: "USD 6,000,000",
        stage: "Series A",
        milestone: "Security Audits",
        status: "Stand by",
      },
      {
        projectName: "Travel Tech Platform",
        target: "USD 1,900,000",
        stage: "Angel Round",
        milestone: "Beta Launch",
        status: "Active",
      },  
];

export const investmentsData = [
  {
    status: "HACKING ATTEMPT",
    project: {
      name: "Project Hack The Planet",
      funding: "000 000",
      totalRaised: "000 000 000",
      stage: "Super Mega Ultra Secret",
      milestone: "World Domination Phase",
      status: "Terminated (by AI Overlord)",
      country: "undisclosed",
      sector: "Cyber Intergalactic"
    }
  },
  {
    status: "SYSTEM ERROR",
    project: {
      name: "Quantum Dark Web Investment",
      funding: "000 000",
      totalRaised: "000 000",
      stage: "Phantom Mode",
      milestone: "Creating the Matrix 2.0",
      status: "Crashed (Literally)",
      country: "the cloud",
      sector: "Quantum"
    }
  },
  {
    status: "SECURITY BREACH: ERROR 404 NOT FOUND",
    project: {
      name: "Hacker's Delight",
      funding: "0.01",
      totalRaised: "0.00",
      stage: "Private Server",
      milestone: "Reached the Outer Limits of Reality",
      status: "Inactive (Server in the Bermuda Triangle)",
      country: "Somewhere Between Dimensions",
      sector: "Cryptocurrency Mining"
    }
  },
  {
    status: "ACCESS GRANTED",
    project: {
      name: "Time Travel Hackathon",
      funding: "42",
      totalRaised: "0",
      stage: "Pre-Singularity",
      milestone: "Reverse the Flux Capacitor",
      status: "Stuck in the '80s",
      country: "The Future",
      sector: "Time-Space"
    }
  },
  {
    status: "DATA LEAK DETECTED",
    project: {
      name: "The Big Data Conspiracy",
      funding: "50 000 000",
      totalRaised: "1 000 000",
      stage: "Super Secret Black Ops",
      milestone: "AI is now self-aware",
      status: "Corrupted (Skynet)",
      country: "Underground Lair",
      sector: "Artificial Intelligence"
    }
  },
  {
    status: "ALERT: Someone forgot to lock the server.",
    project: {
      name: "Invisible Money Hack",
      funding: "1",
      totalRaised: "0",
      stage: "Invisible Stage",
      milestone: "Project Launch (nobody saw it)",
      status: "Compromised (by the interns)",
      country: "Not even on Google Maps",
      sector: "Invisible Cryptocurrency"
    }
  },
  {
    status: "SUSPICIOUS ACTIVITY: Did someone order a pizza?",
    project: {
      name: "Pizza Delivery Drones",
      funding: "5 000 000",
      totalRaised: "100 000 000",
      stage: "Drone Invasion",
      milestone: "Pizza Delivered to Mars",
      status: "On Hold (Due to too much cheese)",
      country: "Mars",
      sector: "Interplanetary Pizza Logistics"
    }
  },
  {
    status: "EXTRA-TERRESTRIAL INTERFERENCE",
    project: {
      name: "Alien Invasion Fund",
      funding: "999 999 999 999",
      totalRaised: "3 000 000 000",
      stage: "Alien Hybrids (don’t ask)",
      milestone: "First Contact",
      status: "Abducted by Aliens",
      country: "Area 51",
      sector: "Intergalactic Colonization"
    }
  }
];


export const employeesData = [
    { name: 'Annette Black',userImg:'/images/img_avatar.png', email: 'annette@digitalmorocco.com', title: 'Back End Developer', type: 'Internship', status: 'Active' },
    { name: 'Dianne Russell', userImg:'/images/img_avatar_62x62.png',  email: 'dianne@digitalmorocco.com', title: 'Software Developer', type: 'Job Share', status: 'Active' },
    { name: 'Floyd Miles',userImg:'/images/img_avatar_1.png', email: 'f.miles@digitalmorocco.com', title: 'Software Development Manager Contract', type: 'Project-Based', status: 'Offline' },
    { name: 'Kathryn Murphy',userImg:'/images/img_avatar_2.png', email: 'kathryn@digitalmorocco.com', title: 'Social Media Manager', type: 'Consultancy', status: 'Active' },
    { name: 'Jerome Bell',userImg:'/images/img_avatar.png', email: 'jerome.bell@digitalmorocco.com', title: 'Project Manager', type: 'Full-Time', status: 'Offline' },
    { name: 'Cameron Williamson',userImg:'/images/img_avatar_3.png', email: 'cameron.w@digitalmorocco.com', title: 'Software Tester', type: 'Part-Time', status: 'Active' },
    { name: 'Darlene Robertson',userImg:'/images/img_avatar_4.png', email: 'darlene@digitalmorocco.com', title: 'Scrum Master', type: 'Temporary', status: 'Active' },
    { name: 'Ralph Edwards',userImg:'/images/img_avatar_5.png', email: 'ralphed@digitalmorocco.com', title: 'UX Designer', type: 'Temporary', status: 'Active' },
    {
        name: 'Grace Foster',
        userImg: '/images/img_avatar.png',
        email: 'grace.foster@digitalmorocco.com',
        title: 'Front End Developer',
        type: 'Internship',
        status: 'Active'
      },
      {
        name: 'Leo Simmons',
        userImg: '/images/img_avatar_7.png',
        email: 'leo.s@digitalmorocco.com',
        title: 'Data Analyst',
        type: 'Full-Time',
        status: 'Active'
      },
      {
        name: 'Violet Adams',
        userImg: '/images/img_avatar_8.png',
        email: 'violet@digitalmorocco.com',
        title: 'Product Manager',
        type: 'Consultancy',
        status: 'Offline'
      },
      {
        name: 'Lucas Gray',
        userImg: '/images/img_avatar_9.png',
        email: 'lucas.gray@digitalmorocco.com',
        title: 'UI Designer',
        type: 'Part-Time',
        status: 'Active'
      },
      {
        name: 'Nora Parker',
        userImg: '/images/img_avatar_10.png',
        email: 'nora.parker@digitalmorocco.com',
        title: 'DevOps Engineer',
        type: 'Project-Based',
        status: 'Active'
      },
      {
        name: 'Oscar Butler',
        userImg: '/images/img_avatar_11.png',
        email: 'oscar@digitalmorocco.com',
        title: 'Database Administrator',
        type: 'Temporary',
        status: 'Offline'
      },
      {
        name: 'Mia Carter',
        userImg: '/images/img_avatar_12.png',
        email: 'mia.c@digitalmorocco.com',
        title: 'Content Creator',
        type: 'Job Share',
        status: 'Active'
      },
      {
        name: 'Ethan Cooper',
        userImg: '/images/img_avatar_62x62.png',
        email: 'ethan.cooper@digitalmorocco.com',
        title: 'System Architect',
        type: 'Full-Time',
        status: 'Active'
      },
      {
        name: 'Ivy Reed',
        userImg: '/images/img_avatar.png',
        email: 'ivy@digitalmorocco.com',
        title: 'Graphic Designer',
        type: 'Consultancy',
        status: 'Offline'
      },
      {
        name: 'Samuel Powell',
        userImg: '/images/img_avatar_1.png',
        email: 'samuel.p@digitalmorocco.com',
        title: 'Quality Assurance Engineer',
        type: 'Part-Time',
        status: 'Active'
      },
      {
        name: 'Ava Simmons',
        userImg: '/images/img_avatar_2.png',
        email: 'ava.s@digitalmorocco.com',
        title: 'Business Analyst',
        type: 'Temporary',
        status: 'Active'
      },
      {
        name: 'Liam Bell',
        userImg: '/images/img_avatar_3.png',
        email: 'liam.bell@digitalmorocco.com',
        title: 'Technical Lead',
        type: 'Project-Based',
        status: 'Offline'
      },
      {
        name: 'Olivia Murphy',
        userImg: '/images/img_avatar_4.png',
        email: 'olivia@digitalmorocco.com',
        title: 'SEO Specialist',
        type: 'Job Share',
        status: 'Active'
      },
      {
        name: 'Noah Collins',
        userImg: '/images/img_avatar_5.png',
        email: 'noah.c@digitalmorocco.com',
        title: 'Network Engineer',
        type: 'Full-Time',
        status: 'Active'
      },
      {
        name: 'Chloe Sanders',
        userImg: '/images/img_avatar_7.png',
        email: 'chloe@digitalmorocco.com',
        title: 'Business Development Manager',
        type: 'Consultancy',
        status: 'Offline'
      },
      {
        name: 'Logan Howard',
        userImg: '/images/img_avatar_1.png',
        email: 'logan.h@digitalmorocco.com',
        title: 'Mobile App Developer',
        type: 'Part-Time',
        status: 'Active'
      },
      {
        name: 'Sophia Ross',
        userImg: '/images/img_avatar_2.png',
        email: 'sophia.r@digitalmorocco.com',
        title: 'Customer Support Specialist',
        type: 'Temporary',
        status: 'Active'
      },
      {
        name: 'Jackson Bennett',
        userImg: '/images/img_avatar_3.png',
        email: 'jackson.b@digitalmorocco.com',
        title: 'Cloud Solutions Architect',
        type: 'Project-Based',
        status: 'Offline'
      },
      {
        name: 'Emma Gray',
        userImg: '/images/img_avatar_4.png',
        email: 'emma@digitalmorocco.com',
        title: 'UI/UX Designer',
        type: 'Job Share',
        status: 'Active'
      },
      {
        name: 'William Jenkins',
        userImg: '/images/img_avatar_5.png',
        email: 'william.j@digitalmorocco.com',
        title: 'Front End Developer',
        type: 'Full-Time',
        status: 'Active'
      },
      {
        name: 'Charlotte Lewis',
        userImg: '/images/img_avatar.png',
        email: 'charlotte@digitalmorocco.com',
        title: 'HR Manager',
        type: 'Consultancy',
        status: 'Offline'
      },
];

export const CompanyLegalData = [
    {
        id: 1,
        title: "Marketing Plan",
        name: "Marketing Plan-Q2 [2024].pdf",
        lastModified: "Mar 6 2024 02:37:22 PM",
        owner: "Annette Black",
        ownerImg: "/images/img_avatar.png"
    },
    {
        id: 2,
        title: "Non-Disclosure Agreement (NDA)",
        name: "Non-Disclosure Agreement (NDA).pdf",
        lastModified: "Mar 16 2024 02:37:22 PM",
        owner: "Darlene Robertson",
        ownerImg: "/images/img_avatar_4.png"
    },
    {
        id: 3,
        title: "Employment Contract",
        name: "Employment Contract_ Annette Black.pdf",
        lastModified: "Mar 4,2024 12:05:58 AM",
        owner: "Annette Black",
        ownerImg: "/images/img_avatar.png"
    },
    {
        id: 4,
        title: "Investor Pitch Deck",
        name: "Investor Pitch Deck Mar 2024.pdf",
        lastModified: "Mar 28,2024 04:01 PM",
        owner: "Annette Black",
        ownerImg: "/images/img_avatar.png"
    },
    {
        id: 5,
        title: "Mou Digital Morocco",
        name: "Mou Digital Morocco.pdf",
        lastModified: "Mar 14 2024 11:20:58 AM",
        owner: "Darlene Robertson",
        ownerImg: "/images/img_avatar_4.png"
    },
    {
        id: 6,
        title: "Partnership Agreement DM",
        name: "Partnership Agreement DM (2024).pdf",
        lastModified: "Mar 30 2024 12:20:56 PM",
        owner: "Cameron Williamson",
        ownerImg: "/images/img_avatar_3.png"
    },
    {
        id: 7,
        title: "Employee Handbook",
        name: "Employee Handbook.pdf",
        lastModified: "Mar 22 2024 12:45:15 PM",
        owner: "Darlene Robertson",
        ownerImg: "/images/img_avatar_4.png"
    },
    {
        id: 8,
        title: "Quality Assurance (QA) Plan",
        name: "Quality Assurance (QA) Plan.pdf",
        lastModified: "Mar 1 2024 10:45:08 AM",
        owner: "Cameron Williamson",
        ownerImg: "/images/img_avatar_3.png"
    },
    {
        id: 9,
        title: "Business Proposal",
        name: "Business Proposal Q3 2024.pdf",
        lastModified: "Apr 10 2024 09:30:45 AM",
        owner: "Annette Black",
        ownerImg: "/images/img_avatar.png"
    },
    {
        id: 10,
        title: "Terms of Service",
        name: "Terms of Service.pdf",
        lastModified: "Apr 20 2024 03:45:12 PM",
        owner: "Darlene Robertson",
        ownerImg: "/images/img_avatar_4.png"
    },
    {
        id: 11,
        title: "Project Scope",
        name: "Project Scope Annette Black.pdf",
        lastModified: "Apr 12 2024 11:55:30 AM",
        owner: "Annette Black",
        ownerImg: "/images/img_avatar.png"
    },
    {
        id: 12,
        title: "Financial Report",
        name: "Financial Report Jul 2024.pdf",
        lastModified: "May 26 2024 11:55:30 AM",
        owner: "Annette Black",
        ownerImg: "/images/img_avatar.png"
    },
    {
        id: 13,
        title: "Trademark Agreement",
        name: "Trademark Agreement.pdf",
        lastModified: "May 8 2024 02:15:10 PM",
        owner: "Darlene Robertson",
        ownerImg: "/images/img_avatar_4.png"
    },
    // {
    //     id: 14,
    //     title: "Service Level Agreement (SLA)",
    //     name: "Service Level Agreement SLA.pdf",
    //     lastModified: "Jul 29 2024 04:20:30 PM",
    //     owner: "Cameron Williamson",
    //     ownerImg: "/images/img_avatar_3.png"
    // },
    // {
    //     id: 15,
    //     title: "Marketing Strategy",
    //     name: "Marketing Strategy Q3 2024.pdf",
    //     lastModified: "Jul 14 2024 12:30:15 PM",
    //     owner: "Darlene Robertson",
    //     ownerImg: "/images/img_avatar_4.png"
    // },
    // {
    //     id: 16,
    //     title: "Product Roadmap",
    //     name: "Product Roadmap Jul 2024.pdf",
    //     lastModified: "Jul 31 2024 11:40:50 AM",
    //     owner: "Cameron Williamson",
    //     ownerImg: "/images/img_avatar_3.png"
    // },
    // {
    //     id: 17,
    //     title: "Data Protection Policy",
    //     name: "Data Protection Policy.pdf",
    //     lastModified: "Jul 23 2024 03:55:25 PM",
    //     owner: "Annette Black",
    //     ownerImg: "/images/img_avatar.png"
    // },
    // {
    //     id: 18,
    //     title: "Sales Agreement",
    //     name: "Sales Agreement Jul 2024.pdf",
    //     lastModified: "May 2 2024 11:15:35 AM",
    //     owner: "Cameron Williamson",
    //     ownerImg: "/images/img_avatar_3.png"
    // },
    // {
    //     id: 19,
    //     title: "User Guide",
    //     name: "User Guide.pdf",
    //     lastModified: "Jul 25 2024 09:15:40 AM",
    //     owner: "Darlene Robertson",
    //     ownerImg: "/images/img_avatar_4.png"
    // },
    // {
    //     id: 20,
    //     title: "Project Charter",
    //     name: "Project Charter May 2024.pdf",
    //     lastModified: "May 4 2024 03:25:10 PM",
    //     owner: "Cameron Williamson",
    //     ownerImg: "/images/img_avatar_3.png"
    // },
    // {
    //     id: 21,
    //     title: "Risk Assessment",
    //     name: "Risk Assessment Jul 2024.pdf",
    //     lastModified: "Jul 27 2024 02:35:10 PM",
    //     owner: "Annette Black",
    //     ownerImg: "/images/img_avatar.png"
    // },
    // {
    //     id: 22,
    //     title: "Code of Conduct",
    //     name: "Code of Conduct.pdf",
    //     lastModified: "May 6 2024 09:40:45 AM",
    //     owner: "Darlene Robertson",
    //     ownerImg: "/images/img_avatar_4.png"
    // },
    // {
    //     id: 23,
    //     title: "Business Case",
    //     name: "Business Case May 2024.pdf",
    //     lastModified: "May 8 2024 02:50:30 PM",
    //     owner: "Cameron Williamson",
    //     ownerImg: "/images/img_avatar_3.png"
    // },
    // {
    //     id: 24,
    //     title: "Training Manual",
    //     name: "Training Manual.pdf",
    //     lastModified: "May 10 2024 11:05:15 AM",
    //     owner: "Annette Black",
    //     ownerImg: "/images/img_avatar.png"
    // },
    // {
    //     id: 25,
    //     title: "IT Policy",
    //     name: "IT Policy May 2024.pdf",
    //     lastModified: "May 12 2024 11:15:35 AM",
    //     owner: "Cameron Williamson",
    //     ownerImg: "/images/img_avatar_3.png"
    // },
    // {
    //     id: 26,
    //     title: "Agile Manifesto",
    //     name: "Agile Manifesto.pdf",
    //     lastModified: "May 14 2024 03:25:10 PM",
    //     owner: "Darlene Robertson",
    //     ownerImg: "/images/img_avatar_4.png"
    // },
    // {
    //     id: 27,
    //     title: "Privacy Policy",
    //     name: "Privacy Policy May 2024.pdf",
    //     lastModified: "May 16 2024 09:40:45 AM",
    //     owner: "Annette Black",
    //     ownerImg: "/images/img_avatar.png"
    // },
    // {
    //     id: 28,
    //     title: "Cybersecurity Policy",
    //     name: "Cybersecurity Policy.pdf",
    //     lastModified: "May 18 2024 02:50:30 PM",
    //     owner: "Cameron Williamson",
    //     ownerImg: "/images/img_avatar_3.png"
    // },
    // {
    //     id: 29,
    //     title: "Workplace Safety Manual",
    //     name: "Workplace Safety Manual.pdf",
    //     lastModified: "May 20 2024 11:05:15 AM",
    //     owner: "Darlene Robertson",
    //     ownerImg: "/images/img_avatar_4.png"
    // },
    // {
    //     id: 30,
    //     title: "Conflict of Interest Policy",
    //     name: "Conflict of Interest Policy.pdf",
    //     lastModified: "May 22 2024 03:25:10 PM",
    //     owner: "Annette Black",
    //     ownerImg: "/images/img_avatar.png"
    // }
];

export const InvestorsData = [
  { 
    logo: "/images/img_inv.svg", 
    name: "Vxydur Hsdtlwxr", 
    type: "Ventvre Cltpil", 
    numberOfInvestment: 231, 
    numberOfExits: 89, 
    location: "Mxqufo, Ixeya", 
    PreferredInvestmentIndustry: "Sss - Oui, c'est un secteur en plein essor, Lrtjfqccisl Yntmglxnce - La science qu'on invente quand on a trop de café, Xnzchine Lyrning - Apprendre aux machines à mieux nous comprendre (ou nous dominer)"
  },
  { 
    logo: "/images/img_inv1.svg", 
    name: "Syxdrtp Hvdungx Zyxpl", 
    type: "Anzrl", 
    numberOfInvestment: 104, 
    numberOfExits: 96, 
    location: "BX Bgfthz, Cplxmzba", 
    PreferredInvestmentIndustry: "Xdptech - Oui, on investit dans les robots avant qu'ils ne nous envahissent, Agrqiltvure - C'est comme la ferme 2.0, Bhotcvqhlogy - Pour tout ce qui est... bio et étrange"
  },
  { 
    logo: "/images/img_inv2.svg", 
    name: "Tlchstzrs Atlsfnx", 
    type: "Ventvre Cltpil", 
    numberOfInvestment: 123, 
    numberOfExits: 72, 
    location: "Lgndfn, Unxgted Kxnzgdm", 
    PreferredInvestmentIndustry: "Xdtech - Des idées que même vos grand-parents trouvent trop compliquées, Agrqiltvure - Plantez des tomates, devenez riche, Bhotcvqhlogy - Parce qu'on a tous besoin de gènes améliorés"
  },
  { 
    logo: "/images/img_inv3.svg", 
    name: "Uvbin-X Xccqrxrztxr", 
    type: "Xccqrxrztxr", 
    numberOfInvestment: 254, 
    numberOfExits: 86, 
    location: "Cxro, Xgpt", 
    PreferredInvestmentIndustry: "Ezytech - On fait de la tech tellement facile que même votre chat pourrait l'utiliser, X-Lxxrnng - Pour apprendre tout ce qu'il ne faut pas savoir, Cxrpzrate Trxrng - Parce qu'une entreprise, c'est bien, mais une entreprise qui pense être un vaisseau spatial, c'est mieux"
  },
  { 
    logo: "/images/img_inv4.svg", 
    name: "Mxsk599 Xccqrxrztxr", 
    type: "Xccqrxrztxr", 
    numberOfInvestment: 342, 
    numberOfExits: 111, 
    location: "Sydfey, Xustrxlix", 
    PreferredInvestmentIndustry: "Bxg Dxte - Vous aimez les gros chiffres ? Nous aussi, Sss - Sauf que c'est moins effrayant que dans les films, Crcwfvndlng - Parce que l'argent est plus fun quand il est bien caché"
  },
  { 
    logo: "/images/img_inv5.svg", 
    name: "Brndxn Wllcty", 
    type: "Anzrl", 
    numberOfInvestment: 213, 
    numberOfExits: 85, 
    location: "Xbv Dhxbl, UXE", 
    PreferredInvestmentIndustry: "Xdtech - La tech du futur, Agrqiltvure - On cultive des idées (et des légumes aussi), Bhotcvqhlogy - Si ça brille, c'est probablement bio"
  },
  { 
    logo: "/images/img_inv11.svg", 
    name: "BlxeYxrd Xctitl", 
    type: "Ventvre Cltpil", 
    numberOfInvestment: 120, 
    numberOfExits: 65, 
    location: "Brlyn, Gxrmzny", 
    PreferredInvestmentIndustry: "Blzckchn - Les chaînes qui sont un peu trop obscures pour vous, Cxlpto - L'argent que vous ne comprenez pas, mais vous l'aimez quand même, Fnntxch - Pour les moments où vous voulez dépenser plus que vous ne gagnez"
  },
  { 
    logo: "/images/img_inv12.svg", 
    name: "599 Stxrtxps", 
    type: "Xccqrxrztxr", 
    numberOfInvestment: 500, 
    numberOfExits: 250, 
    location: "Sn Fxncrsxco, XUS", 
    PreferredInvestmentIndustry: "E-cqmmfrce - Oui, nous vendons tout en ligne (même des idées), Hlztthtech - Parce que parfois la tech doit être un peu médicinale, Mbblxe - Si vous avez un téléphone, vous êtes déjà notre client"
  },
  { 
    logo: "/images/img_inv2.svg", 
    name: "Sxxdcvmp", 
    type: "Sxd Fvnd", 
    numberOfInvestment: 200, 
    numberOfExits: 120, 
    location: "Lgndfn, Unxgted Kxnzgdm", 
    PreferredInvestmentIndustry: "Fnntxch - Parce que tout se passe dans les cartes bancaires, Sss - Les serpents sont le futur, Mxrktplxce - Nous vendons tout, même l'air"
  }
];


export const InvestorsRequestData = [
    {
        date: "May 22, 2024 10:30 AM",
        investorName: "Venture Catalysts",
        communicationStatus: "Initial email sent",
        status: "In Progress",
        attachment: "Pitch Deck",
        notes: "Meeting Scheduled"
      },
      {
        date: "May 18, 2024 03:32 AM",
        investorName: "Startup Funding Club",
        communicationStatus: "Phone call",
        status: "Approved",
        attachment: "Proposal",
        notes: "Positive Feedback"
      },
      {
        date: "Mar 24, 2024 09:06 AM",
        investorName: "XYZ Combinator",
        communicationStatus: "Rejection email",
        status: "Rejected",
        attachment: "Financials",
        notes: "Not Interested"
      },
      {
        date: "Mar 06, 2024 01:32 PM",
        investorName: "Techstars Atlanta",
        communicationStatus: "Ongoing discussions",
        status: "In Progress",
        attachment: "Partnership Proposal",
        notes: "Awaiting Response"
      },
      {
        date: "Mar 26, 2024 10:44 AM",
        investorName: "Urban-X Accelerator",
        communicationStatus: "Initial contact call",
        status: "Approved",
        attachment: "Business Plan",
        notes: ""
      },
      {
        date: "Mar 23, 2024 03:12 PM",
        investorName: "Misk500 Accelerator",
        communicationStatus: "Follow-up email",
        status: "Rejected",
        attachment: "Pitch Deck",
        notes: "Not a good fit"
      },
      {
        date: "Mar 16, 2024 06:44 PM",
        investorName: "Brendan Wallace",
        communicationStatus: "Initial discussion",
        status: "In Progress",
        attachment: "Proposal",
        notes: "Awaiting Response"
      },
      {
        date: "Feb 28, 2024 11:05 AM",
        investorName: "NextLevel Management",
        communicationStatus: "Phone call",
        status: "In Progress",
        attachment: "Business Plan",
        notes: "Awaiting Response"
      },
      {
        date: "Feb 14, 2024 02:30 PM",
        investorName: "Sequoia Capital",
        communicationStatus: "Initial email sent",
        status: "In Progress",
        attachment: "Pitch Deck",
        notes: "Meeting Scheduled"
    },
    {
        date: "Jan 28, 2024 09:45 AM",
        investorName: "Accel",
        communicationStatus: "Phone call",
        status: "Approved",
        attachment: "Proposal",
        notes: "Positive Feedback"
    },
    {
        date: "Jan 10, 2024 11:20 AM",
        investorName: "Greylock Partners",
        communicationStatus: "Rejection email",
        status: "Rejected",
        attachment: "Financials",
        notes: "Not Interested"
    },
    {
        date: "Dec 30, 2022 03:15 PM",
        investorName: "Andreessen Horowitz",
        communicationStatus: "Ongoing discussions",
        status: "In Progress",
        attachment: "Partnership Proposal",
        notes: "Awaiting Response"
    },
    {
        date: "Dec 22, 2022 08:30 AM",
        investorName: "Benchmark",
        communicationStatus: "Initial contact call",
        status: "Approved",
        attachment: "Business Plan",
        notes: ""
    },
    {
        date: "Nov 18, 2022 05:55 PM",
        investorName: "Lightspeed Venture Partners",
        communicationStatus: "Follow-up email",
        status: "Rejected",
        attachment: "Pitch Deck",
        notes: "Not a good fit"
    },
    {
        date: "Oct 30, 2022 12:10 PM",
        investorName: "Accelerator",
        communicationStatus: "Initial discussion",
        status: "In Progress",
        attachment: "Proposal",
        notes: "Awaiting Response"
    },
    {
        date: "Oct 15, 2022 03:20 PM",
        investorName: "NEA",
        communicationStatus: "Phone call",
        status: "In Progress",
        attachment: "Business Plan",
        notes: "Awaiting Response"
    },
    {
        date: "Sep 28, 2022 09:35 AM",
        investorName: "Bessemer Venture Partners",
        communicationStatus: "Initial email sent",
        status: "In Progress",
        attachment: "Pitch Deck",
        notes: "Meeting Scheduled"
    },
    {
        date: "Sep 12, 2022 01:40 PM",
        investorName: "Founders Fund",
        communicationStatus: "Phone call",
        status: "Approved",
        attachment: "Proposal",
        notes: "Positive Feedback"
    },
    {
        date: "May 30, 2022 05:15 PM",
        investorName: "Insight Partners",
        communicationStatus: "Rejection email",
        status: "Rejected",
        attachment: "Financials",
        notes: "Not Interested"
    },
    {
        date: "May 22, 2022 10:20 AM",
        investorName: "CRV",
        communicationStatus: "Ongoing discussions",
        status: "In Progress",
        attachment: "Partnership Proposal",
        notes: "Awaiting Response"
    },
    {
        date: "Jul 18, 2022 02:45 PM",
        investorName: "Battery Ventures",
        communicationStatus: "Initial contact call",
        status: "Approved",
        attachment: "Business Plan",
        notes: ""
    },
    {
        date: "Mar 30, 2022 11:55 AM",
        investorName: "Redpoint Ventures",
        communicationStatus: "Follow-up email",
        status: "Rejected",
        attachment: "Pitch Deck",
        notes: "Not a good fit"
    },
    {
        date: "Mar 14, 2022 04:30 PM",
        investorName: "Accelerator",
        communicationStatus: "Initial discussion",
        status: "In Progress",
        attachment: "Proposal",
        notes: "Awaiting Response"
    },
    {
        date: "May 28, 2022 08:20 AM",
        investorName: "Angel",
        communicationStatus: "Phone call",
        status: "In Progress",
        attachment: "Business Plan",
        notes: "Awaiting Response"
    },
    {
        date: "May 10, 2022 01:10 PM",
        investorName: "Andreessen Horowitz",
        communicationStatus: "Initial email sent",
        status: "In Progress",
        attachment: "Pitch Deck",
        notes: "Meeting Scheduled"
    },
    {
        date: "Apr 22, 2022 10:15 AM",
        investorName: "Sequoia Capital",
        communicationStatus: "Phone call",
        status: "Approved",
        attachment: "Proposal",
        notes: "Positive Feedback"
    },
    {
        date: "Apr 10, 2022 03:40 PM",
        investorName: "Angel",
        communicationStatus: "Rejection email",
        status: "Rejected",
        attachment: "Financials",
        notes: "Not Interested"
    },
    {
        date: "Mar 28, 2022 09:50 AM",
        investorName: "Greylock Partners",
        communicationStatus: "Ongoing discussions",
        status: "In Progress",
        attachment: "Partnership Proposal",
        notes: "Awaiting Response"
    },
    {
        date: "Mar 12, 2022 02:35 PM",
        investorName: "Venture Capital",
        communicationStatus: "Initial contact call",
        status: "Approved",
        attachment: "Business Plan",
        notes: ""
    },
];

export const eventData = [
  {
    image: "/images/event0.jpeg",
    eventName: "Monthly #FirstFridayFair Business, Data & Tech (Virtual Event)",
    organizer: "Jefferson Capital",
    logo:"/images/img_inv.svg",
    dateTime: "Jan 11, 2024  01:49 pm",
    eventtype: "Ongoing Event",
    location: "Casablanca"
  },
  {
    image: "/images/event1.jpeg",
    eventName: "Women Who Network - Virtual Speed Networking Event",
    organizer: "Startup Funding Club",
    logo:"/images/img_inv1.svg" ,
    dateTime: "Mar 13, 2024  08:05 am",
    eventtype: "Upcoming Event",
    location: "Casablanca"
  },
  {
    image: "/images/event2.jpeg",
    eventName: "Workshop: Découvre Laravel: Introduction au Framework PHP",
    organizer: "GoMyCode",
    logo:"/images/img_inv12.svg" ,
    dateTime: "Feb 21, 2024  03:05 pm",
    eventtype: "Upcoming Event",
    location: "Rabat"
  },
  {
    image: "/images/event4.jpeg",
    eventName: "Next Big Investment Opportunity in North Africa",
    organizer: "XYZ Combinator",
    logo:"/images/img_inv2.svg" ,
    dateTime: "Jan 1, 2024  01:49 pm",
    eventtype: "Upcoming Event",
    location: "Marrakech"
  },
  {
    image: "/images/event5.jpeg",
    eventName: "Tech Hackathon",
    organizer: "North Africa Dreamin’",
    logo:"/images/img_inv8.png" ,
    dateTime: "May 3, 2024  12:10 am",
    eventtype: "Past Event",
    location: "Agadir"
  },
  {
    image: "/images/event6.jpeg",
    eventName: "Generative AI for startups. Next generation startups",
    organizer: "Business Connect",
    logo:"/images/img_inv9.png" ,
    dateTime: "Feb 21, 2024  03:05 pm",
    eventtype: "Past Event",
    location: "Tangier"
  },
  {
    image: "/images/event7.jpeg",
    eventName: "Digital Marketing Workshop",
    organizer: "Karizma Consulting Group",
    logo:"/images/img_inv10.png" ,
    dateTime: "Oct 13, 2024  08:05 am",
    eventtype: "Upcoming Event",
    location: "Fez"
  },
  {
    image: "/images/event1.jpeg",
    eventName: "TEDiscussion Casablanca #31 : The inside story of ChatGPT potential !",
    organizer: "DeepLearning.AI community",
    logo:"/images/img_inv11.svg",
    dateTime: "Sep 4, 2021  12:14 am",
    eventtype: "Ongoing Event",
    location: "Ouarzazate"
  },
  {
    image: "/images/event2.jpeg",
    eventName: "Artificial Intelligence in Healthcare Summit",
    organizer: "MedTech Innovators",
    logo: "/images/img_inv3.svg",
    dateTime: "Apr 5, 2024 02:30 pm",
    eventtype: "Ongoing Event",
    location: "Tetouan"
},
{
    image: "/images/event3.jpeg",
    eventName: "E-commerce Masterclass",
    organizer: "Online Business Academy",
    logo: "/images/img_inv4.svg",
    dateTime: "May 15, 2024 09:00 am",
    eventtype: "Upcoming Event",
    location: "Meknes"
},
{
    image: "/images/event4.jpeg",
    eventName: "Blockchain Technology Seminar",
    organizer: "CryptoHub",
    logo: "/images/img_inv5.svg",
    dateTime: "Mar 20, 2024 03:00 pm",
    eventtype: "Past Event",
    location: "Essaouira"
},
{
    image: "/images/event5.jpeg",
    eventName: "Startup Pitch Competition",
    organizer: "Morocco Startup Hub",
    logo: "/images/img_inv6.svg",
    dateTime: "Jul 10, 2024 10:00 am",
    eventtype: "Upcoming Event",
    location: "El Jadida"
},
{
    image: "/images/event6.jpeg",
    eventName: "Mobile App Development Workshop",
    organizer: "AppMasters",
    logo: "/images/img_inv7.svg",
    dateTime: "May 25, 2024 04:00 pm",
    eventtype: "Ongoing Event",
    location: "Chefchaouen"
},
{
    image: "/images/event7.jpeg",
    eventName: "Fintech Conference",
    organizer: "Finance Futures",
    logo: "/images/img_inv8.png",
    dateTime: "Sep 15, 2024 11:00 am",
    eventtype: "Past Event",
    location: "Taza"
},
{
    image: "/images/event0.jpeg",
    eventName: "Cloud Computing Symposium",
    organizer: "CloudTech Solutions",
    logo: "/images/img_inv9.png",
    dateTime: "Oct 10, 2024 02:30 pm",
    eventtype: "Upcoming Event",
    location: "Beni Mellal"
},
{
    image: "/images/event1.jpeg",
    eventName: "Digital Transformation Workshop",
    organizer: "Tech Innovators",
    logo: "/images/img_inv10.png",
    dateTime: "Nov 20, 2024 09:00 am",
    eventtype: "Ongoing Event",
    location: "Nador"
},
{
    image: "/images/event2.jpeg",
    eventName: "Social Media Marketing Summit",
    organizer: "Marketing Masters",
    logo: "/images/img_inv11.svg",
    dateTime: "Dec 5, 2024 03:00 pm",
    eventtype: "Past Event",
    location: "Al Hoceima"
},
{
    image: "/images/event3.jpeg",
    eventName: "Cybersecurity Conference",
    organizer: "SecureTech",
    logo: "/images/img_inv12.svg",
    dateTime: "Jan 15, 2024 10:00 am",
    eventtype: "Upcoming Event",
    location: "Larache"
},
{
    image: "/images/event4.jpeg",
    eventName: "Data Science Bootcamp",
    organizer: "Data Experts",
    logo: "/images/img_inv.svg",
    dateTime: "Feb 20, 2024 04:00 pm",
    eventtype: "Ongoing Event",
    location: "Khouribga"
},
{
    image: "/images/event5.jpeg",
    eventName: "Green Technology Summit",
    organizer: "EcoInnovate",
    logo: "/images/img_inv1.svg",
    dateTime: "Mar 10, 2024 11:00 am",
    eventtype: "Past Event",
    location: "Asilah"
},
{
    image: "/images/event6.jpeg",
    eventName: "Startup Accelerator Program",
    organizer: "Morocco Accelerate",
    logo: "/images/img_inv11.svg",
    dateTime: "Apr 15, 2024 02:30 pm",
    eventtype: "Upcoming Event",
    location: "Oujda"
},
{
    image: "/images/event7.jpeg",
    eventName: "IoT (Internet of Things) Workshop",
    organizer: "IoT Innovators",
    logo: "/images/img_inv12.svg",
    dateTime: "May 5, 2024 09:00 am",
    eventtype: "Ongoing Event",
    location: "Safi"
},
{
    image: "/images/event1.jpeg",
    eventName: "Virtual Reality Symposium",
    organizer: "VR World",
    logo: "/images/img_inv2.svg",
    dateTime: "Mar 20, 2024 03:00 pm",
    eventtype: "Past Event",
    location: "Taroudant"
},
{
    image: "/images/event2.jpeg",
    eventName: "AgriTech Conference",
    organizer: "AgriInnovate",
    logo: "/images/img_inv3.svg",
    dateTime: "Jul 10, 2024 10:00 am",
    eventtype: "Upcoming Event",
    location: "Tan Tan"
},
{
    image: "/images/event0.jpeg",
    eventName: "E-commerce Expo",
    organizer: "EcomExpo",
    logo: "/images/img_inv12.svg",
    dateTime: "May 25, 2024 04:00 pm",
    eventtype: "Ongoing Event",
    location: "Sefrou"
},
{
    image: "/images/event3.jpeg",
    eventName: "Aerospace Technology Summit",
    organizer: "AeroTech",
    logo: "/images/img_inv4.svg",
    dateTime: "Sep 15, 2024 11:00 am",
    eventtype: "Past Event",
    location: "El Kelaa des Sraghna"
},
{
    image: "/images/event4.jpeg",
    eventName: "EduTech Symposium",
    organizer: "EduInnovators",
    logo: "/images/img_inv5.svg",
    dateTime: "Oct 10, 2024 02:30 pm",
    eventtype: "Upcoming Event",
    location: "Berrechid"
},
{
    image: "/images/event5.jpeg",
    eventName: "Renewable Energy Workshop",
    organizer: "EcoPower",
    logo: "/images/img_inv6.svg",
    dateTime: "Nov 20, 2024 09:00 am",
    eventtype: "Ongoing Event",
    location: "Tiznit"
},
];

export const UpcomingEvents = [
  {
    image: "/images/event0.jpeg",
    name: 'Monthly #FirstFridayFair Business, Data & Technology Virtual Event',
    date: 'Fri, Sep 1, 2024',
    time: '11:00 AM',
    location: 'Virtual Event, Online Only',
    price: '$0.00',
    tags: 'Business, Data & Technology',
},
{
    image: "/images/event2.jpeg",
    name: 'Eventsize - A Comprehensive Marketing and Sales Virtual Workshop',
    date: 'Fri, Oct 18, 2024',
    time: '10:00 AM - 12:00 PM',
    location: 'Online Event',
    price: '',
    tags: 'Marketing, Sales',
},
{
    image: "/images/event3.jpeg",
    name: 'Startup Grind - Moscow Edition Focusing on Startups and Entrepreneurship',
    date: 'Thu, Oct 24, 2024',
    time: '6:00 PM - 9:00 PM',
    location: 'TechHub, Moscow',
    price: '$10.00',
    tags: 'Startups, Entrepreneurship',
},
{
    image: "/images/event4.jpeg",
    name: 'Productized - A Full-Day Online Event on Product Management and Design',
    date: 'Fri, Oct 25, 2024',
    time: '10:00 AM - 5:00 PM',
    location: 'Online Event',
    price: '$249.00',
    tags: 'Product Management, Design',
},
{
    image: "/images/event5.jpeg",
    name: 'Design Sprint - An Exclusive Design and Innovation Workshop at Google Moscow',
    date: 'Mon, Oct 28, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Google Office, Moscow',
    price: '$1,200.00',
    tags: 'Design, Innovation',
},
{
    image: "/images/event5.jpeg",
    name: 'Agile Conference - Moscow Edition Focusing on Agile and Software Development',
    date: 'Tue, Oct 29, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Digital October, Moscow',
    price: '$500.00',
    tags: 'Agile, Software Development',
},
{
    image: "/images/event7.jpeg",
    name: 'Data Science Summit - A Premier Event on Data Science and Machine Learning',
    date: 'Wed, Oct 30, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Skolkovo, Moscow',
    price: '$750.00',
    tags: 'Data Science, Machine Learning',
},
{
    image: "/images/event0.jpeg",
    name: 'DevOps Summit - Moscow Edition Focusing on DevOps and Cloud Computing',
    date: 'Thu, Oct 31, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Microsoft, Moscow',
    price: '$600.00',
    tags: 'DevOps, Cloud Computing',
},
{
    image: "/images/event1.jpeg",
    name: 'Cloud Conference - Moscow Edition Focusing on Cloud Computing and AWS',
    date: 'Fri, Nov 1, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Amazon, Moscow',
    price: '$800.00',
    tags: 'Cloud Computing, AWS',
},
{
    image: "/images/event2.jpeg",
    name: 'AI Conference - Moscow Edition Focusing on Artificial Intelligence and Machine Learning',
    date: 'Sat, Nov 2, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'IBM, Moscow',
    price: '$900.00',
    tags: 'Artificial Intelligence, Machine Learning',
},
{
    image: "/images/event3.jpeg",
    name: 'Blockchain Summit - Moscow Edition Focusing on Blockchain and Cryptocurrency',
    date: 'Sun, Nov 3, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Ethereum, Moscow',
    price: '$1,000.00',
    tags: 'Blockchain, Cryptocurrency',
},
{
  image: "/images/event6.jpeg",
  name: 'E-commerce Summit - A Premier Event on Online Retail and Digital Sales',
  date: 'Mon, Nov 4, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Online Event',
  price: '$550.00',
  tags: 'E-commerce, Digital Sales',
},
{
  image: "/images/event7.jpeg",
  name: 'Cybersecurity Conference - Moscow Edition Focusing on Cybersecurity and Privacy',
  date: 'Tue, Nov 5, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Microsoft, Moscow',
  price: '$700.00',
  tags: 'Cybersecurity, Privacy',
},
{
  image: "/images/event5.jpeg",
  name: 'Fintech Forum - Moscow Edition Focusing on Financial Technology and Innovation',
  date: 'Wed, Nov 6, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Sberbank, Moscow',
  price: '$850.00',
  tags: 'Fintech, Innovation',
},
{
  image: "/images/event0.jpeg",
  name: 'Healthtech Summit - A Premier Event on Healthcare Technology and Digital Health',
  date: 'Thu, Nov 7, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Digital October, Moscow',
  price: '$600.00',
  tags: 'Healthtech, Digital Health',
},
{
  image: "/images/event6.jpeg",
  name: 'IoT Conference - Moscow Edition Focusing on Internet of Things and Connectivity',
  date: 'Fri, Nov 8, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Yandex, Moscow',
  price: '$750.00',
  tags: 'IoT, Connectivity',
},
{
  image: "/images/event2.jpeg",
  name: 'RetailTech Summit - A Premier Event on Retail Technology and Omnichannel Strategies',
  date: 'Sat, Nov 9, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Online Event',
  price: '$500.00',
  tags: 'RetailTech, Omnichannel',
},
{
  image: "/images/event3.jpeg",
  name: 'VR & AR Conference - Moscow Edition Focusing on Virtual Reality and Maymented Reality',
  date: 'Sun, Nov 10, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Mail.ru Group, Moscow',
  price: '$900.00',
  tags: 'VR, AR',
},
{
  image: "/images/event5.jpeg",
  name: 'Edtech Summit - A Premier Event on Educational Technology and E-learning',
  date: 'Mon, Nov 11, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Skolkovo, Moscow',
  price: '$650.00',
  tags: 'Edtech, E-learning',
},
{
  image: "/images/event4.jpeg",
  name: 'GreenTech Conference - Moscow Edition Focusing on Sustainable Technology and Green Innovation',
  date: 'Tue, Nov 12, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Microsoft, Moscow',
  price: '$700.00',
  tags: 'GreenTech, Sustainability',
},
{
  image: "/images/event0.jpeg",
  name: 'Gaming Summit - A Premier Event on Gaming Technology and Esports',
  date: 'Wed, Nov 13, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Digital October, Moscow',
  price: '$550.00',
  tags: 'Gaming, Esports',
},
{
  image: "/images/event1.jpeg",
  name: 'Robotics Conference - Moscow Edition Focusing on Robotics and Automation',
  date: 'Thu, Nov 14, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Yandex, Moscow',
  price: '$800.00',
  tags: 'Robotics, Automation',
},
{
  image: "/images/event2.jpeg",
  name: 'Telecom Summit - A Premier Event on Telecommunications and Connectivity',
  date: 'Fri, Nov 15, 2024',
  time: '9:00 AM - 5:00 PM',
  location: 'Sberbank, Moscow',
  price: '$650.00',
  tags: 'Telecom, Connectivity',
},
];

export const PastEvents = [
  {
    image: "/images/event0.jpeg",
    name: 'Monthly #FirstFridayFair Business, Data & Technology Virtual Event',
    date: 'Fri, Sep 1, 2024',
    time: '11:00 AM',
    location: 'Virtual Event [Online Only]',
    price: '$0.00',
    tags: 'Business, Data & Technology',
    canParticipate: true,
    isPas:true
  },
  {
    image: "/images/event1.jpeg",
    name: 'AnalyticsWEEK - The Premier Data Analytics Online Conference',
    date: 'Fri, Apr 5, 2024',
    time: '11:00 AM - 2:00 PM',
    location: 'Online Only',
    price: '',
    tags: 'Analytics',
    canParticipate: false,
    isPas:true
  },
  {
    image: "/images/event2.jpeg",
    name: 'Eventsize - A Comprehensive Marketing and Sales Virtual Workshop',
    date: 'Fri, Oct 18, 2024',
    time: '10:00 AM - 12:00 PM',
    location: 'Rabat',
    price: '',
    tags: 'Marketing, Sales',
    canParticipate: true,
    isPas:true
  },
  {
    image: "/images/event3.jpeg",
    name: 'Startup Grind - Moscow Edition Focusing on Startups and Entrepreneurship',
    date: 'Thu, Oct 24, 2024',
    time: '6:00 PM - 9:00 PM',
    location: 'Mohammed VI Polytechnic University',
    price: '$10.00',
    tags: 'Startups, Entrepreneurship',
    canParticipate: true,
    isPas:true
  },
  {
    image: "/images/event4.jpeg",
    name: 'Productized - A Full-Day Online Event on Product Management and Design',
    date: 'Fri, Oct 25, 2024',
    time: '10:00 AM - 5:00 PM',
    location: 'Online Event',
    price: '$249.00',
    tags: 'Product Management, Design',
    canParticipate: false,
    isPas:true
  },
  {
    image: "/images/event5.jpeg",
    name: 'Design Sprint - An Exclusive Design and Innovation Workshop at Google Moscow',
    date: 'Mon, Oct 28, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Google Office, Moscow',
    price: '$1,200.00',
    tags: 'Design, Innovation',
    canParticipate: false,
    isPas:true
  },
  {
    image: "/images/event5.jpeg",
    name: 'Agile Conference - Moscow Edition Focusing on Agile and Software Development',
    date: 'Tue, Oct 29, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Digital October, Moscow',
    price: '$500.00',
    tags: 'Agile, Software Development',
    canParticipate: true,
    isPas:true
  },
  {
    image: "/images/event7.jpeg",
    name: 'Data Science Summit - A Premier Event on Data Science and Machine Learning',
    date: 'Wed, Oct 30, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Skolkovo, Moscow',
    price: '$750.00',
    tags: 'Data Science, Machine Learning',
    canParticipate: false,
    isPas:true
  },
  {
    image: "/images/event0.jpeg",
    name: 'DevOps Summit - Moscow Edition Focusing on DevOps and Cloud Computing',
    date: 'Thu, Oct 31, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Microsoft, Moscow',
    price: '$600.00',
    tags: 'DevOps, Cloud Computing',
    canParticipate: false,
    isPas:true
  },
  {
    image: "/images/event1.jpeg",
    name: 'Cloud Conference - Moscow Edition Focusing on Cloud Computing and AWS',
    date: 'Fri, Nov 1, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Amazon, Moscow',
    price: '$800.00',
    tags: 'Cloud Computing, AWS',
    canParticipate: true,
    isPas:true
  },
  {
    image: "/images/event2.jpeg",
    name: 'AI Conference - Moscow Edition Focusing on Artificial Intelligence and Machine Learning',
    date: 'Sat, Nov 2, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'IBM, Moscow',
    price: '$900.00',
    tags: 'Artificial Intelligence, Machine Learning',
    canParticipate: false,
    isPas:true
  },
  {
    image: "/images/event3.jpeg",
    name: 'Blockchain Summit - Moscow Edition Focusing on Blockchain and Cryptocurrency',
    date: 'Sun, Nov 3, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Ethereum, Moscow',
    price: '$1,000.00',
    tags: 'Blockchain, Cryptocurrency',
    canParticipate: true,
    isPas:true
  },
  {
    image: "/images/event6.jpeg",
    name: 'E-commerce Summit - A Premier Event on Online Retail and Digital Sales',
    date: 'Mon, Nov 4, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Online Event',
    price: '$550.00',
    tags: 'E-commerce, Digital Sales',
    canParticipate: true,
    isPas:true
},
{
    image: "/images/event7.jpeg",
    name: 'Cybersecurity Conference - Moscow Edition Focusing on Cybersecurity and Privacy',
    date: 'Tue, Nov 5, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Microsoft, Moscow',
    price: '$700.00',
    tags: 'Cybersecurity, Privacy',
    canParticipate: false,
    isPas:true
},
{
    image: "/images/event2.jpeg",
    name: 'Fintech Forum - Moscow Edition Focusing on Financial Technology and Innovation',
    date: 'Wed, Nov 6, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Sberbank, Moscow',
    price: '$850.00',
    tags: 'Fintech, Innovation',
    canParticipate: true,
    isPas:true
},
{
    image: "/images/event5.jpeg",
    name: 'Healthtech Summit - A Premier Event on Healthcare Technology and Digital Health',
    date: 'Thu, Nov 7, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Digital October, Moscow',
    price: '$600.00',
    tags: 'Healthtech, Digital Health',
    canParticipate: false,
    isPas:true
},
{
    image: "/images/event0.jpeg",
    name: 'IoT Conference - Moscow Edition Focusing on Internet of Things and Connectivity',
    date: 'Fri, Nov 8, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Yandex, Moscow',
    price: '$750.00',
    tags: 'IoT, Connectivity',
    canParticipate: true,
    isPas:true
},
{
    image: "/images/event1.jpeg",
    name: 'RetailTech Summit - A Premier Event on Retail Technology and Omnichannel Strategies',
    date: 'Sat, Nov 9, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Online Event',
    price: '$500.00',
    tags: 'RetailTech, Omnichannel',
    canParticipate: false,
    isPas:true
},
{
    image: "/images/event2.jpeg",
    name: 'VR & AR Conference - Moscow Edition Focusing on Virtual Reality and Maymented Reality',
    date: 'Sun, Nov 10, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Mail.ru Group, Moscow',
    price: '$900.00',
    tags: 'VR, AR',
    canParticipate: true,
    isPas:true
},
{
    image: "/images/event3.jpeg",
    name: 'Edtech Summit - A Premier Event on Educational Technology and E-learning',
    date: 'Mon, Nov 11, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Skolkovo, Moscow',
    price: '$650.00',
    tags: 'Edtech, E-learning',
    canParticipate: false,
    isPas:true
},
{
    image: "/images/event4.jpeg",
    name: 'GreenTech Conference - Moscow Edition Focusing on Sustainable Technology and Green Innovation',
    date: 'Tue, Nov 12, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Microsoft, Moscow',
    price: '$700.00',
    tags: 'GreenTech, Sustainability',
    canParticipate: true,
    isPas:true
},
{
    image: "/images/event5.jpeg",
    name: 'Gaming Summit - A Premier Event on Gaming Technology and Esports',
    date: 'Wed, Nov 13, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Digital October, Moscow',
    price: '$550.00',
    tags: 'Gaming, Esports',
    canParticipate: false,
    isPas:true
},
{
    image: "/images/event6.jpeg",
    name: 'Robotics Conference - Moscow Edition Focusing on Robotics and Automation',
    date: 'Thu, Nov 14, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Yandex, Moscow',
    price: '$800.00',
    tags: 'Robotics, Automation',
    canParticipate: true,
    isPas:true
},
{
    image: "/images/event7.jpeg",
    name: 'Telecom Summit - A Premier Event on Telecommunications and Connectivity',
    date: 'Fri, Nov 15, 2024',
    time: '9:00 AM - 5:00 PM',
    location: 'Sberbank, Moscow',
    price: '$650.00',
    tags: 'Telecom, Connectivity',
    canParticipate: false,
    isPas:true
}
];

export const historyData = [
  {
    image:"/images/img_avatar.png",
    name: 'Annette Black',
    date: 'Mar 6, 2024 02:37:22 PM',
    action: 'Shared Document',
    actionTitle:"Investor Pitch Deck - Mar 2024.pdf"
  },
  {
    image:"/images/img_avatar_1.png",
    name: 'Darlene Robertson',
    date: 'Mar 16, 2024 02:37:22 PM',
    action: 'Send Contact Request to',
    actionTitle:"XYZ Combinator"
  },
  {
    image:"/images/img_avatar.png",
    name: 'Annette Black',
    date: 'Mar 4, 2024 11:20:56 AM',
    action: 'Uploaded Document',
    actionTitle:"Partnership Agreement - DM (2024).pdf"
  },
  {
    image:"/images/img_avatar.png",
    name: 'Annette Black',
    date: 'Mar 28, 2024 04:01 PM',
    action: 'Register an Event',
    actionTitle:"Generative Al for startups. Next generation startups"
  },
  {
    image:"/images/img_avatar_1.png",
    name: 'Darlene Robertson',
    date: 'Mar 14, 2024 11:20:56 AM',
    action: 'Create Project',
    actionTitle:"Lorem Ipsum Project - Angel Round Investment",
    actionTitle2:"and save as Draft"
  },
  {
    image:"/images/img_avatar_1.png",
    name: 'Darlene Robertson',
    date: 'Mar 30, 2024 11:20:56 PM',
    action: 'Attending Event',
    actionTitle:"Next Big Investment Opportunity in North Africa"
  },
  {
    image:"/images/img_avatar_4.png",
    name: 'Cameron Williamson',
    date: 'Mar 22, 2024 12:45:15 PM',
    action: 'Shared Document',
    actionTitle:"Marketing Plan.pdf",
    actionTitle2:"to Marketing team"
  },
  {
    image:"/images/img_avatar_2.png",
    name: 'Gregory Turner',
    date: 'May 12, 2024 10:00:00 AM',
    action: 'Sent Proposal to',
    actionTitle:"Potential Investor"
},
{
    image:"/images/img_avatar_3.png",
    name: 'Linda Garcia',
    date: 'May 16, 2024 01:50:45 PM',
    action: 'Updated Project',
    actionTitle:"Status to In Progress"
},
{
    image:"/images/img_avatar_4.png",
    name: 'Cameron Williamson',
    date: 'May 21, 2024 12:30:20 PM',
    action: 'Received Feedback on',
    actionTitle:"Marketing Campaign"
},
{
    image:"/images/img_avatar_5.png",
    name: 'Trevor Evans',
    date: 'May 25, 2024 02:45:15 PM',
    action: 'Attended Conference on',
    actionTitle:"Digital Marketing"
},

];

export const languages = [
  { id: 'en', label: 'English' },
  { id: 'fr', label: 'Français' },
];

export const regions = [
  { id: 'af', label: 'Africa' },
  { id: 'an', label: 'Antarctica' },
  { id: 'as', label: 'Asia' },
  { id: 'eu', label: 'Europe' },
  { id: 'na', label: 'North America' },
  { id: 'oc', label: 'Oceania' },
  { id: 'sa', label: 'South America' },
];


export const paymentMethodsData = [
  { id: 1, name: 'Mastercard', image: '/images/img_mastercard.svg', icon: "/images/img_mastercard_icon.svg", info: 'Mastercard information' },
  { id: 2, name: 'Visa', image: '/images/img_visa.svg',icon: "/images/img_visa.svg", info: 'Visa information' },
  { id: 3, name: 'Paypal', image: '/images/img_paypal.svg', info: 'Paypal information' },
  { id: 4, name: 'Google Pay', image: '/images/img_googlepay.svg', info: 'Google Pay information' },
  { id: 5, name: 'Apple Pay', image: '/images/img_applepay.svg', info: 'Apple Pay information' },
];

export const historyEventMessages = {
  profile_updated: "Profile updated successfully.",
  profile_update_lang_reg: "Updated user's language and region preferences.",
  document_shared: "Shared document: ",
  contact_request_sent: "Sent a contact request to ",
  event_registered: "Registered for event",
  project_created: "Created project",
  event_attended: "Attended event",
  document_created: "Uploaded document",
  document_uploaded: "Uploaded document",
  legal_document_created: "Uploaded legal document",
  legal_document_updated: "Updated legal document",
  legal_document_deleted: "Deleted legal document",
  project_status_updated: "Project status updated:",
  company_created: "Created a new company",
  subscription_renew: "Subscription renewed successfully",
  subscription_canceled: "Subscription canceled.",
  subscription_upgraded: "Subscription upgraded to",
  new_subscription: "New subscription for plan",
  password_changed: "Password changed successfully",
  account_deleted: "Account deleted successfully",
  project_updated: "Updated project",
  project_deleted: "Deleted project",
  project_shared: "Shared project",
  employee_added: "Added a new employee to ",
  employee_removed: "Removed an employee from ",
  employee_updated: "Updated employee details for ",
  company_updated: "Updated company:",
  document_deleted: "Deleted document ",
  document_updated: "Updated document ",
  event_ticket_download: "Downloaded ticket for event ",
  event_ticket_view: "Viewed ticket for event.",
  contact_request_accepted: "Contact request Accepted for project",
  contact_request_approved: "Contact request approved for project",
  contact_request_rejected: "Contact request rejected for project",
  sponsor_request_send: "Sent a sponsor request for event ",
  sponsor_request_approved: "Sponsor request approved for event",
  sponsor_request_rejected: "Sponsor request rejected for event",
  password_reset: "Password reset action completed successfully.",
  default: "An action was performed."
};


export const countries = [
  {
    "name": "Afghanistan",
    "isoCode": "AF",
    "iso3": "AFG",
    "numericCode": "004"
  },
  {
    "name": "Albania",
    "isoCode": "AL",
    "iso3": "ALB",
    "numericCode": "008"
  },
  {
    "name": "Algeria",
    "isoCode": "DZ",
    "iso3": "DZA",
    "numericCode": "012"
  },
  {
    "name": "Andorra",
    "isoCode": "AD",
    "iso3": "AND",
    "numericCode": "020"
  },
  {
    "name": "Angola",
    "isoCode": "AO",
    "iso3": "AGO",
    "numericCode": "024"
  },
  {
    "name": "Antigua and Barbuda",
    "isoCode": "AG",
    "iso3": "ATG",
    "numericCode": "028"
  },
  {
    "name": "Argentina",
    "isoCode": "AR",
    "iso3": "ARG",
    "numericCode": "032"
  },
  {
    "name": "Armenia",
    "isoCode": "AM",
    "iso3": "ARM",
    "numericCode": "051"
  },
  {
    "name": "Australia",
    "isoCode": "AU",
    "iso3": "AUS",
    "numericCode": "036"
  },
  {
    "name": "Austria",
    "isoCode": "AT",
    "iso3": "AUT",
    "numericCode": "040"
  },
  {
    "name": "Azerbaijan",
    "isoCode": "AZ",
    "iso3": "AZE",
    "numericCode": "031"
  },
  {
    "name": "Bahamas",
    "isoCode": "BS",
    "iso3": "BHS",
    "numericCode": "044"
  },
  {
    "name": "Bahrain",
    "isoCode": "BH",
    "iso3": "BHR",
    "numericCode": "048"
  },
  {
    "name": "Bangladesh",
    "isoCode": "BD",
    "iso3": "BGD",
    "numericCode": "050"
  },
  {
    "name": "Barbados",
    "isoCode": "BB",
    "iso3": "BRB",
    "numericCode": "052"
  },
  {
    "name": "Belarus",
    "isoCode": "BY",
    "iso3": "BLR",
    "numericCode": "112"
  },
  {
    "name": "Belgium",
    "isoCode": "BE",
    "iso3": "BEL",
    "numericCode": "056"
  },
  {
    "name": "Belize",
    "isoCode": "BZ",
    "iso3": "BLZ",
    "numericCode": "084"
  },
  {
    "name": "Benin",
    "isoCode": "BJ",
    "iso3": "BEN",
    "numericCode": "204"
  },
  {
    "name": "Bhutan",
    "isoCode": "BT",
    "iso3": "BTN",
    "numericCode": "064"
  },
  {
    "name": "Bolivia",
    "isoCode": "BO",
    "iso3": "BOL",
    "numericCode": "068"
  },
  {
    "name": "Bosnia and Herzegovina",
    "isoCode": "BA",
    "iso3": "BIH",
    "numericCode": "070"
  },
  {
    "name": "Botswana",
    "isoCode": "BW",
    "iso3": "BWA",
    "numericCode": "072"
  },
  {
    "name": "Brazil",
    "isoCode": "BR",
    "iso3": "BRA",
    "numericCode": "076"
  },
  {
    "name": "Brunei Darussalam",
    "isoCode": "BN",
    "iso3": "BRN",
    "numericCode": "096"
  },
  {
    "name": "Bulgaria",
    "isoCode": "BG",
    "iso3": "BGR",
    "numericCode": "100"
  },
  {
    "name": "Burkina Faso",
    "isoCode": "BF",
    "iso3": "BFA",
    "numericCode": "854"
  },
  {
    "name": "Burundi",
    "isoCode": "BI",
    "iso3": "BDI",
    "numericCode": "108"
  },
  {
    "name": "Cape Verde",
    "isoCode": "CV",
    "iso3": "CPV",
    "numericCode": "132"
  },
  {
    "name": "Cambodia",
    "isoCode": "KH",
    "iso3": "KHM",
    "numericCode": "116"
  },
  {
    "name": "Cameroon",
    "isoCode": "CM",
    "iso3": "CMR",
    "numericCode": "120"
  },
  {
    "name": "Canada",
    "isoCode": "CA",
    "iso3": "CAN",
    "numericCode": "124"
  },
  {
    "name": "Central African Republic",
    "isoCode": "CF",
    "iso3": "CAF",
    "numericCode": "140"
  },
  {
    "name": "Chad",
    "isoCode": "TD",
    "iso3": "TCD",
    "numericCode": "148"
  },
  {
    "name": "Chile",
    "isoCode": "CL",
    "iso3": "CHL",
    "numericCode": "152"
  },
  {
    "name": "People's Republic of China",
    "isoCode": "CN",
    "iso3": "CHN",
    "numericCode": "156"
  },
  {
    "name": "Colombia",
    "isoCode": "CO",
    "iso3": "COL",
    "numericCode": "170"
  },
  {
    "name": "Comoros",
    "isoCode": "KM",
    "iso3": "COM",
    "numericCode": "174"
  },
  {
    "name": "Democratic Republic of the Congo",
    "isoCode": "CD",
    "iso3": "COD",
    "numericCode": "180"
  },
  {
    "name": "Republic of the Congo",
    "isoCode": "CG",
    "iso3": "COG",
    "numericCode": "178"
  },
  {
    "name": "Costa Rica",
    "isoCode": "CR",
    "iso3": "CRI",
    "numericCode": "188"
  },
  {
    "name": "Cote d'Ivoire",
    "isoCode": "CI",
    "iso3": "CIV",
    "numericCode": "384"
  },
  {
    "name": "Croatia",
    "isoCode": "HR",
    "iso3": "HRV",
    "numericCode": "191"
  },
  {
    "name": "Cuba",
    "isoCode": "CU",
    "iso3": "CUB",
    "numericCode": "192"
  },
  {
    "name": "Cyprus",
    "isoCode": "CY",
    "iso3": "CYP",
    "numericCode": "196"
  },
  {
    "name": "Czech Republic",
    "isoCode": "CZ",
    "iso3": "CZE",
    "numericCode": "203"
  },
  {
    "name": "Denmark",
    "isoCode": "DK",
    "iso3": "DNK",
    "numericCode": "208"
  },
  {
    "name": "Djibouti",
    "isoCode": "DJ",
    "iso3": "DJI",
    "numericCode": "262"
  },
  {
    "name": "Dominica",
    "isoCode": "DM",
    "iso3": "DMA",
    "numericCode": "212"
  },
  {
    "name": "Dominican Republic",
    "isoCode": "DO",
    "iso3": "DOM",
    "numericCode": "214"
  },
  {
    "name": "Ecuador",
    "isoCode": "EC",
    "iso3": "ECU",
    "numericCode": "218"
  },
  {
    "name": "Egypt",
    "isoCode": "EG",
    "iso3": "EGY",
    "numericCode": "818"
  },
  {
    "name": "El Salvador",
    "isoCode": "SV",
    "iso3": "SLV",
    "numericCode": "222"
  },
  {
    "name": "Equatorial Guinea",
    "isoCode": "GQ",
    "iso3": "GNQ",
    "numericCode": "226"
  },
  {
    "name": "Eritrea",
    "isoCode": "ER",
    "iso3": "ERI",
    "numericCode": "232"
  },
  {
    "name": "Estonia",
    "isoCode": "EE",
    "iso3": "EST",
    "numericCode": "233"
  },
  {
    "name": "Eswatini",
    "isoCode": "SZ",
    "iso3": "SWZ",
    "numericCode": "748"
  },
  {
    "name": "Ethiopia",
    "isoCode": "ET",
    "iso3": "ETH",
    "numericCode": "231"
  },
  {
    "name": "Fiji",
    "isoCode": "FJ",
    "iso3": "FJI",
    "numericCode": "242"
  },
  {
    "name": "Finland",
    "isoCode": "FI",
    "iso3": "FIN",
    "numericCode": "246"
  },
  {
    "name": "France",
    "isoCode": "FR",
    "iso3": "FRA",
    "numericCode": "250"
  },
  {
    "name": "Gabon",
    "isoCode": "GA",
    "iso3": "GAB",
    "numericCode": "266"
  },
  {
    "name": "Republic of The Gambia",
    "isoCode": "GM",
    "iso3": "GMB",
    "numericCode": "270"
  },
  {
    "name": "Georgia",
    "isoCode": "GE",
    "iso3": "GEO",
    "numericCode": "268"
  },
  {
    "name": "Germany",
    "isoCode": "DE",
    "iso3": "DEU",
    "numericCode": "276"
  },
  {
    "name": "Ghana",
    "isoCode": "GH",
    "iso3": "GHA",
    "numericCode": "288"
  },
  {
    "name": "Greece",
    "isoCode": "GR",
    "iso3": "GRC",
    "numericCode": "300"
  },
  {
    "name": "Grenada",
    "isoCode": "GD",
    "iso3": "GRD",
    "numericCode": "308"
  },
  {
    "name": "Guatemala",
    "isoCode": "GT",
    "iso3": "GTM",
    "numericCode": "320"
  },
  {
    "name": "Guinea",
    "isoCode": "GN",
    "iso3": "GIN",
    "numericCode": "324"
  },
  {
    "name": "Guinea-Bissau",
    "isoCode": "GW",
    "iso3": "GNB",
    "numericCode": "624"
  },
  {
    "name": "Guyana",
    "isoCode": "GY",
    "iso3": "GUY",
    "numericCode": "328"
  },
  {
    "name": "Haiti",
    "isoCode": "HT",
    "iso3": "HTI",
    "numericCode": "332"
  },
  {
    "name": "Honduras",
    "isoCode": "HN",
    "iso3": "HND",
    "numericCode": "340"
  },
  {
    "name": "Hungary",
    "isoCode": "HU",
    "iso3": "HUN",
    "numericCode": "348"
  },
  {
    "name": "Iceland",
    "isoCode": "IS",
    "iso3": "ISL",
    "numericCode": "352"
  },
  {
    "name": "India",
    "isoCode": "IN",
    "iso3": "IND",
    "numericCode": "356"
  },
  {
    "name": "Indonesia",
    "isoCode": "ID",
    "iso3": "IDN",
    "numericCode": "360"
  },
  {
    "name": "Islamic Republic of Iran",
    "isoCode": "IR",
    "iso3": "IRN",
    "numericCode": "364"
  },
  {
    "name": "Iraq",
    "isoCode": "IQ",
    "iso3": "IRQ",
    "numericCode": "368"
  },
  {
    "name": "Ireland",
    "isoCode": "IE",
    "iso3": "IRL",
    "numericCode": "372"
  },
  {
    "name": "Israel",
    "isoCode": "IL",
    "iso3": "ISR",
    "numericCode": "376"
  },
  {
    "name": "Italy",
    "isoCode": "IT",
    "iso3": "ITA",
    "numericCode": "380"
  },
  {
    "name": "Jamaica",
    "isoCode": "JM",
    "iso3": "JAM",
    "numericCode": "388"
  },
  {
    "name": "Japan",
    "isoCode": "JP",
    "iso3": "JPN",
    "numericCode": "392"
  },
  {
    "name": "Jordan",
    "isoCode": "JO",
    "iso3": "JOR",
    "numericCode": "400"
  },
  {
    "name": "Kazakhstan",
    "isoCode": "KZ",
    "iso3": "KAZ",
    "numericCode": "398"
  },
  {
    "name": "Kenya",
    "isoCode": "KE",
    "iso3": "KEN",
    "numericCode": "404"
  },
  {
    "name": "Kiribati",
    "isoCode": "KI",
    "iso3": "KIR",
    "numericCode": "296"
  },
  {
    "name": "North Korea",
    "isoCode": "KP",
    "iso3": "PRK",
    "numericCode": "408"
  },
  {
    "name": "South Korea",
    "isoCode": "KR",
    "iso3": "KOR",
    "numericCode": "410"
  },
  {
    "name": "Kuwait",
    "isoCode": "KW",
    "iso3": "KWT",
    "numericCode": "414"
  },
  {
    "name": "Kyrgyzstan",
    "isoCode": "KG",
    "iso3": "KGZ",
    "numericCode": "417"
  },
  {
    "name": "Lao People's Democratic Republic",
    "isoCode": "LA",
    "iso3": "LAO",
    "numericCode": "418"
  },
  {
    "name": "Latvia",
    "isoCode": "LV",
    "iso3": "LVA",
    "numericCode": "428"
  },
  {
    "name": "Lebanon",
    "isoCode": "LB",
    "iso3": "LBN",
    "numericCode": "422"
  },
  {
    "name": "Lesotho",
    "isoCode": "LS",
    "iso3": "LSO",
    "numericCode": "426"
  },
  {
    "name": "Liberia",
    "isoCode": "LR",
    "iso3": "LBR",
    "numericCode": "430"
  },
  {
    "name": "Libya",
    "isoCode": "LY",
    "iso3": "LBY",
    "numericCode": "434"
  },
  {
    "name": "Liechtenstein",
    "isoCode": "LI",
    "iso3": "LIE",
    "numericCode": "438"
  },
  {
    "name": "Lithuania",
    "isoCode": "LT",
    "iso3": "LTU",
    "numericCode": "440"
  },
  {
    "name": "Luxembourg",
    "isoCode": "LU",
    "iso3": "LUX",
    "numericCode": "442"
  },
  {
    "name": "Madagascar",
    "isoCode": "MG",
    "iso3": "MDG",
    "numericCode": "450"
  },
  {
    "name": "Malawi",
    "isoCode": "MW",
    "iso3": "MWI",
    "numericCode": "454"
  },
  {
    "name": "Malaysia",
    "isoCode": "MY",
    "iso3": "MYS",
    "numericCode": "458"
  },
  {
    "name": "Maldives",
    "isoCode": "MV",
    "iso3": "MDV",
    "numericCode": "462"
  },
  {
    "name": "Mali",
    "isoCode": "ML",
    "iso3": "MLI",
    "numericCode": "466"
  },
  {
    "name": "Malta",
    "isoCode": "MT",
    "iso3": "MLT",
    "numericCode": "470"
  },
  {
    "name": "Marshall Islands",
    "isoCode": "MH",
    "iso3": "MHL",
    "numericCode": "584"
  },
  {
    "name": "Mauritania",
    "isoCode": "MR",
    "iso3": "MRT",
    "numericCode": "478"
  },
  {
    "name": "Mauritius",
    "isoCode": "MU",
    "iso3": "MUS",
    "numericCode": "480"
  },
  {
    "name": "Mexico",
    "isoCode": "MX",
    "iso3": "MEX",
    "numericCode": "484"
  },
  {
    "name": "Micronesia, Federated States of",
    "isoCode": "FM",
    "iso3": "FSM",
    "numericCode": "583"
  },
  {
    "name": "Moldova, Republic of",
    "isoCode": "MD",
    "iso3": "MDA",
    "numericCode": "498"
  },
  {
    "name": "Monaco",
    "isoCode": "MC",
    "iso3": "MCO",
    "numericCode": "492"
  },
  {
    "name": "Mongolia",
    "isoCode": "MN",
    "iso3": "MNG",
    "numericCode": "496"
  },
  {
    "name": "Montenegro",
    "isoCode": "ME",
    "iso3": "MNE",
    "numericCode": "499"
  },
  {
    "name": "Morocco",
    "isoCode": "MA",
    "iso3": "MAR",
    "numericCode": "504"
  },
  {
    "name": "Mozambique",
    "isoCode": "MZ",
    "iso3": "MOZ",
    "numericCode": "508"
  },
  {
    "name": "Myanmar",
    "isoCode": "MM",
    "iso3": "MMR",
    "numericCode": "104"
  },
  {
    "name": "Namibia",
    "isoCode": "NA",
    "iso3": "NAM",
    "numericCode": "516"
  },
  {
    "name": "Nauru",
    "isoCode": "NR",
    "iso3": "NRU",
    "numericCode": "520"
  },
  {
    "name": "Nepal",
    "isoCode": "NP",
    "iso3": "NPL",
    "numericCode": "524"
  },
  {
    "name": "Netherlands",
    "isoCode": "NL",
    "iso3": "NLD",
    "numericCode": "528"
  },
  {
    "name": "New Zealand",
    "isoCode": "NZ",
    "iso3": "NZL",
    "numericCode": "554"
  },
  {
    "name": "Nicaragua",
    "isoCode": "NI",
    "iso3": "NIC",
    "numericCode": "558"
  },
  {
    "name": "Niger",
    "isoCode": "NE",
    "iso3": "NER",
    "numericCode": "562"
  },
  {
    "name": "Nigeria",
    "isoCode": "NG",
    "iso3": "NGA",
    "numericCode": "566"
  },
  {
    "name": "The Republic of North Macedonia",
    "isoCode": "MK",
    "iso3": "MKD",
    "numericCode": "807"
  },
  {
    "name": "Norway",
    "isoCode": "NO",
    "iso3": "NOR",
    "numericCode": "578"
  },
  {
    "name": "Oman",
    "isoCode": "OM",
    "iso3": "OMN",
    "numericCode": "512"
  },
  {
    "name": "Pakistan",
    "isoCode": "PK",
    "iso3": "PAK",
    "numericCode": "586"
  },
  {
    "name": "Palau",
    "isoCode": "PW",
    "iso3": "PLW",
    "numericCode": "585"
  },
  {
    "name": "Panama",
    "isoCode": "PA",
    "iso3": "PAN",
    "numericCode": "591"
  },
  {
    "name": "Papua New Guinea",
    "isoCode": "PG",
    "iso3": "PNG",
    "numericCode": "598"
  },
  {
    "name": "Paraguay",
    "isoCode": "PY",
    "iso3": "PRY",
    "numericCode": "600"
  },
  {
    "name": "Peru",
    "isoCode": "PE",
    "iso3": "PER",
    "numericCode": "604"
  },
  {
    "name": "Philippines",
    "isoCode": "PH",
    "iso3": "PHL",
    "numericCode": "608"
  },
  {
    "name": "Poland",
    "isoCode": "PL",
    "iso3": "POL",
    "numericCode": "616"
  },
  {
    "name": "Portugal",
    "isoCode": "PT",
    "iso3": "PRT",
    "numericCode": "620"
  },
  {
    "name": "Qatar",
    "isoCode": "QA",
    "iso3": "QAT",
    "numericCode": "634"
  },
  {
    "name": "Romania",
    "isoCode": "RO",
    "iso3": "ROU",
    "numericCode": "642"
  },
  {
    "name": "Russian Federation",
    "isoCode": "RU",
    "iso3": "RUS",
    "numericCode": "643"
  },
  {
    "name": "Rwanda",
    "isoCode": "RW",
    "iso3": "RWA",
    "numericCode": "646"
  },
  {
    "name": "Saint Kitts and Nevis",
    "isoCode": "KN",
    "iso3": "KNA",
    "numericCode": "659"
  },
  {
    "name": "Saint Lucia",
    "isoCode": "LC",
    "iso3": "LCA",
    "numericCode": "662"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "isoCode": "VC",
    "iso3": "VCT",
    "numericCode": "670"
  },
  {
    "name": "Samoa",
    "isoCode": "WS",
    "iso3": "WSM",
    "numericCode": "882"
  },
  {
    "name": "San Marino",
    "isoCode": "SM",
    "iso3": "SMR",
    "numericCode": "674"
  },
  {
    "name": "Sao Tome and Principe",
    "isoCode": "ST",
    "iso3": "STP",
    "numericCode": "678"
  },
  {
    "name": "Saudi Arabia",
    "isoCode": "SA",
    "iso3": "SAU",
    "numericCode": "682"
  },
  {
    "name": "Senegal",
    "isoCode": "SN",
    "iso3": "SEN",
    "numericCode": "686"
  },
  {
    "name": "Serbia",
    "isoCode": "RS",
    "iso3": "SRB",
    "numericCode": "688"
  },
  {
    "name": "Seychelles",
    "isoCode": "SC",
    "iso3": "SYC",
    "numericCode": "690"
  },
  {
    "name": "Sierra Leone",
    "isoCode": "SL",
    "iso3": "SLE",
    "numericCode": "694"
  },
  {
    "name": "Singapore",
    "isoCode": "SG",
    "iso3": "SGP",
    "numericCode": "702"
  },
  {
    "name": "Slovakia",
    "isoCode": "SK",
    "iso3": "SVK",
    "numericCode": "703"
  },
  {
    "name": "Slovenia",
    "isoCode": "SI",
    "iso3": "SVN",
    "numericCode": "705"
  },
  {
    "name": "Solomon Islands",
    "isoCode": "SB",
    "iso3": "SLB",
    "numericCode": "090"
  },
  {
    "name": "Somalia",
    "isoCode": "SO",
    "iso3": "SOM",
    "numericCode": "706"
  },
  {
    "name": "South Africa",
    "isoCode": "ZA",
    "iso3": "ZAF",
    "numericCode": "710"
  },
  {
    "name": "South Sudan",
    "isoCode": "SS",
    "iso3": "SSD",
    "numericCode": "728"
  },
  {
    "name": "Spain",
    "isoCode": "ES",
    "iso3": "ESP",
    "numericCode": "724"
  },
  {
    "name": "Sri Lanka",
    "isoCode": "LK",
    "iso3": "LKA",
    "numericCode": "144"
  },
  {
    "name": "Sudan",
    "isoCode": "SD",
    "iso3": "SDN",
    "numericCode": "729"
  },
  {
    "name": "Suriname",
    "isoCode": "SR",
    "iso3": "SUR",
    "numericCode": "740"
  },
  {
    "name": "Sweden",
    "isoCode": "SE",
    "iso3": "SWE",
    "numericCode": "752"
  },
  {
    "name": "Switzerland",
    "isoCode": "CH",
    "iso3": "CHE",
    "numericCode": "756"
  },
  {
    "name": "Syrian Arab Republic",
    "isoCode": "SY",
    "iso3": "SYR",
    "numericCode": "760"
  },
  {
    "name": "Tajikistan",
    "isoCode": "TJ",
    "iso3": "TJK",
    "numericCode": "762"
  },
  {
    "name": "United Republic of Tanzania",
    "isoCode": "TZ",
    "iso3": "TZA",
    "numericCode": "834"
  },
  {
    "name": "Thailand",
    "isoCode": "TH",
    "iso3": "THA",
    "numericCode": "764"
  },
  {
    "name": "Timor-Leste",
    "isoCode": "TL",
    "iso3": "TLS",
    "numericCode": "626"
  },
  {
    "name": "Togo",
    "isoCode": "TG",
    "iso3": "TGO",
    "numericCode": "768"
  },
  {
    "name": "Tonga",
    "isoCode": "TO",
    "iso3": "TON",
    "numericCode": "776"
  },
  {
    "name": "Trinidad and Tobago",
    "isoCode": "TT",
    "iso3": "TTO",
    "numericCode": "780"
  },
  {
    "name": "Tunisia",
    "isoCode": "TN",
    "iso3": "TUN",
    "numericCode": "788"
  },
  {
    "name": "Türkiye",
    "isoCode": "TR",
    "iso3": "TUR",
    "numericCode": "792"
  },
  {
    "name": "Turkmenistan",
    "isoCode": "TM",
    "iso3": "TKM",
    "numericCode": "795"
  },
  {
    "name": "Tuvalu",
    "isoCode": "TV",
    "iso3": "TUV",
    "numericCode": "798"
  },
  {
    "name": "Uganda",
    "isoCode": "UG",
    "iso3": "UGA",
    "numericCode": "800"
  },
  {
    "name": "Ukraine",
    "isoCode": "UA",
    "iso3": "UKR",
    "numericCode": "804"
  },
  {
    "name": "United Arab Emirates",
    "isoCode": "AE",
    "iso3": "ARE",
    "numericCode": "784"
  },
  {
    "name": "United Kingdom",
    "isoCode": "GB",
    "iso3": "GBR",
    "numericCode": "826"
  },
  {
    "name": "United States of America",
    "isoCode": "US",
    "iso3": "USA",
    "numericCode": "840"
  },
  {
    "name": "Uruguay",
    "isoCode": "UY",
    "iso3": "URY",
    "numericCode": "858"
  },
  {
    "name": "Uzbekistan",
    "isoCode": "UZ",
    "iso3": "UZB",
    "numericCode": "860"
  },
  {
    "name": "Vanuatu",
    "isoCode": "VU",
    "iso3": "VUT",
    "numericCode": "548"
  },
  {
    "name": "Holy See (Vatican City State)",
    "isoCode": "VA",
    "iso3": "VAT",
    "numericCode": "336"
  },
  {
    "name": "Venezuela",
    "isoCode": "VE",
    "iso3": "VEN",
    "numericCode": "862"
  },
  {
    "name": "Vietnam",
    "isoCode": "VN",
    "iso3": "VNM",
    "numericCode": "704"
  },
  {
    "name": "Yemen",
    "isoCode": "YE",
    "iso3": "YEM",
    "numericCode": "887"
  },
  {
    "name": "Zambia",
    "isoCode": "ZM",
    "iso3": "ZMB",
    "numericCode": "894"
  },
  {
    "name": "Zimbabwe",
    "isoCode": "ZW",
    "iso3": "ZWE",
    "numericCode": "716"
  },
  {
    "name": "State of Palestine",
    "isoCode": "PS",
    "iso3": "PSE",
    "numericCode": "275"
  },
  {
    "name": "Taiwan, Province of China",
    "isoCode": "TW",
    "iso3": "TWN",
    "numericCode": "158"
  }
];


